import { find, findAll, offset } from "../utils/dom";
import axios from "axios";

const PageNoticias = () => {
    const page = find("[js-page='noticias']");
    if (!page) return;

    const BASE_URL = `${window.location.protocol}//${window.location.host}`;
    //let Global_Offset = 1;
    let Global_Limit = 7;
    let Global_Todos_Post = 1;


    function reset_menu_categoria() {
        const Menu_Categoria = findAll(".menu-categories-blog .active");
        Menu_Categoria.forEach((menu) => {
            menu.classList.remove("active");
        })
    }

    function add_menu_categoria(id) {
        const Menu_Categoria = find(`.menu-categories-blog ${id}`);
        Menu_Categoria.classList.add("active");
    }

    function busca_categoria() {
        let Busca_Categoria = window.location.href.split("?")
        if (!Busca_Categoria[1]) return "";

        Busca_Categoria = Busca_Categoria[1].split("&");
        if (!Busca_Categoria) return "";


        let Categoria = ''

        for (let busca of Busca_Categoria) {
            if (busca.includes("categoria")) {
                Categoria = busca.split("=")[1];

                reset_menu_categoria();
                add_menu_categoria(`#${Categoria}`);

            }
        }

        return Categoria;
    }

    function load_posts(limit = 7) {
        let Categoria = busca_categoria();


        axios.get(`${BASE_URL}/api/posts/?limit=${limit}${(Categoria != '') ? `&categoria=${Categoria}` : ''}`).then(function (response) {
            const Container_Posts = find('[js-posts]');
            if (!Container_Posts) return;

            const Resultados = response.data.results;
            let posts = '';

            for (let i = 0; i < Resultados.length; i++) {
                posts += `
                    <div class="${(i < 4) ? "col-xl-6" : "col-xl-4"}">
                        <div class="card-article">
                            <a href="${BASE_URL}/noticias/${Resultados[i].slug}" class="image">
                                
                                <img loading="lazy" src="${(Resultados[i].banner) ? Resultados[i].banner : `${BASE_URL}/static/img/default/default.webp`}" alt="">

                                <span class="category">
                                    ${Resultados[i].categoria}
                                </span>
                            </a>
                            <div class="box-text">
                                <a href="${BASE_URL}/noticias/${Resultados[i].slug}" class="title">${Resultados[i].titulo}</a>
                            </div>
                        </div>
                    </div>
                `
            }

            Container_Posts.innerHTML = posts;
            Global_Todos_Post = Resultados.length;


        }).catch(function (error) {
            console.log(error);
        });
    }

    function botao_all_posts() {
        const Botao_mais_posts = find('[js-more-posts]');
        if (!Botao_mais_posts) return;

        Botao_mais_posts.addEventListener("click", (e) => {
            e.preventDefault();
            Global_Limit = Global_Limit + 7;

            load_posts(Global_Limit);

            if (Global_Todos_Post <= Global_Limit) {
                e.target.style.display = "none";
            }

        })
    }

    load_posts();
    botao_all_posts();
}

export default PageNoticias;