import dragAndMove from "../modules/dragAndMove";
import { find, findAll } from "../utils/dom";
import axios from "axios";

const Page_Resultados = () => {
    const SinglePlayOff = find("[js-page='single_torneio_playoff']");
    if (!SinglePlayOff) return;


    // states 
    let equipeOrIndividual = 'equipe';


    function agrupamentoDeFases(arr) {
        // Objeto para agrupar os itens por fase
        const fases = {};

        // Loop para agrupar os itens por fase
        arr.forEach((item) => {
            const fase = item.fase;
            // Se a fase ainda não existir no objeto 'fases', cria uma chave para ela com um array vazio
            if (!fases[fase]) {
                fases[fase] = [];
            }
            // Adiciona o item ao array da fase correspondente
            fases[fase].push(item);
        });

        console.log('fases', fases)
        return fases
    }


    function renderTimes(partida) {

        const data_resultado = partida?.data_resultado
        let formattedDate = false
        let formatHorario = false
        if (data_resultado) {
            const [data, horario] = data_resultado.split('T');
            const [year, month, day] = data?.split('-');
            formattedDate = `${day}/${month}`;
            const [hours, minutes, seconds] = horario?.split(':');
            formatHorario = `${hours}:${minutes}`
        }

        const equipeOrAtleta = equipeOrIndividual == 'equipe' ? 'equipe' : 'atleta_inscrito'
        const placarEquipeOrAtleta = equipeOrIndividual == 'equipe' ? 'placar_equipe' : 'placar_atleta'


        if (partida[equipeOrAtleta + '_1'] && partida[equipeOrAtleta + '_2']) {
            return `<div class="time-wrapper ${Number(partida[placarEquipeOrAtleta + '_1']) > Number(partida[placarEquipeOrAtleta + '_2']) ? 'winner' : 'loser'}">
                        <div class="time-data-horario">${formattedDate ? formattedDate : '-'}</div>
                        <div class="time-time">
                            <div class="time-imagem">
                                <img src="${partida[equipeOrAtleta + '_1']?.logo}" alt="">
                            </div>
                            <p class="time-nome">
                                ${partida[equipeOrAtleta + '_1']?.nome}
                            </p>
                        </div>
                        <div class="time-placar">
                            <p>${partida[placarEquipeOrAtleta + '_1']}</p>
                        </div>
                    </div>
                <div class="time-wrapper ${Number(partida[placarEquipeOrAtleta + '_2']) > Number(partida[placarEquipeOrAtleta + '_1']) ? 'winner' : 'loser'}">
                    <div class="time-data-horario">${formatHorario ? formatHorario : '-'}</div>
                    <div class="time-time">
                        <div class="time-imagem">
                            <img src="${partida[equipeOrAtleta + '_2']?.logo}" alt="">
                        </div>
                        <p class="time-nome">
                            ${partida[equipeOrAtleta + '_2']?.nome}
                        </p>
                    </div>
                    <div class="time-placar">
                        <p>${partida[placarEquipeOrAtleta + '_2']}</p>
                    </div>
                </div>
                `
        } else if (partida[equipeOrAtleta + '_1'] && !partida[equipeOrAtleta + '_2']) {
            return `
                <div class="time-wrapper">
                        <div class="time-data-horario">${formattedDate ? formattedDate : '-'}</div>
                        <div class="time-time">
                            <div class="time-imagem">
                                <img src="${partida[equipeOrAtleta + '_1']?.logo}" alt="">
                            </div>
                            <p class="time-nome">
                                ${partida[equipeOrAtleta + '_1']?.nome}
                            </p>
                        </div>
                        <div class="time-placar">
                            <p>${partida[placarEquipeOrAtleta + '_1']}</p>
                        </div>
                    </div>
                <div class="time-wrapper">
                    <div class="time-data-horario">${formatHorario ? formatHorario : '-'}</div>
                    <div class="time-time">
                        <div class="time-imagem">
                            <img src="" alt="">
                        </div>
                        <p class="time-nome">
                            -
                        </p>
                    </div>
                    <div class="time-placar">
                        <p>-</p>
                    </div>
                </div>
            `
        } else {
            return `
                <div class="time-wrapper">
                    <div class="time-data-horario">${formatHorario ? formatHorario : '-'}</div>
                    <div class="time-time">
                        <div class="time-imagem">
                            <img src="" alt="">
                        </div>
                        <p class="time-nome">
                            -
                        </p>
                    </div>
                    <div class="time-placar">
                        <p>-</p>
                    </div>
                </div>
                <div class="time-wrapper">
                    <div class="time-data-horario">${formatHorario ? formatHorario : '-'}</div>
                    <div class="time-time">
                        <div class="time-imagem">
                            <img src="" alt="">
                        </div>
                        <p class="time-nome">
                            -
                        </p>
                    </div>
                    <div class="time-placar">
                        <p>-</p>
                    </div>
                </div>
            
            `
        }

    }

    function renderRodadas(arr, position) {

        const fases = agrupamentoDeFases(arr)
        return `
            <div class="todas-fases text-white mb-32">
                <swiper-container class="mySwiper" space-between="40" slides-per-view="auto">
                ${Object.keys(fases).map((key, i) => `<swiper-slide class="!w-fit"><a href="#rodada_${position}_${equipeOrIndividual}_${i + 1}" class="link-fases">Rodada ${i + 1}</a></swiper-slide>`).reverse().join('')}
                </swiper-container>
            </div>
        `
        // return `<div class="todas-fases text-white">
        //             <div class="todas-fases-wrapper ">
        //              ${Object.keys(fases).map((key,i) => `<p>Rodada ${i + 1}</p>`).join('')}                     
        //             </div>
        //         </div>`
    }

    function renderFases(arr, position) {


        if (!arr && arr.length > 0) {
            return ''
        }

        const separacaoFases = agrupamentoDeFases(arr)

        const fasesHTML = Object.keys(separacaoFases).map((key, i) => {

            return `
                    <div id="rodada_${position}_${equipeOrIndividual}_${i + 1}" class="fase-wrapper  fase-${i + 1}">
                        ${separacaoFases[key].map(partida => {
                // atleta_inscrito_1

                // if(equipeOrIndividual == 'equipe'){
                //     if(!partida.equipe_1 &&  !partida.equipe_2){
                //         return ''
                //     }

                // }else if (equipeOrIndividual == 'individual'){
                //     if(!partida.atleta_inscrito_1 &&  !partida.atleta_inscrito_2){
                //         return ''
                //     }

                // }

                return `<div class="times ">
                                        ${renderTimes(partida)}
                                    </div>`
            }).join('')}
                    </div>
                    `
        }).join('')

        return fasesHTML

    }
    const loadData = (result, equipeOrIndividual) => {
        const content_Resultados = find("[js-resultado-modalidades]");

        const partidasSuperior = equipeOrIndividual == 'equipe' ? result.partidas_chave_superior_equipe : result.partidas_chave_superior_individual
        const partidasInferior = equipeOrIndividual == 'equipe' ? result.partidas_chave_inferior_equipe : result.partidas_chave_inferior_individual

        const htmlModalidades = `   <div class="torneio-wrapper ${result.possui_chave_inferior ? 'tem-chave-inferior' : ''}">
                                        <div class="chave-superior">
                                            
                                        ${result.possui_chave_inferior ? `<h2 class="title-chave">Chave superior</h2>` : ''}
                                            

                                            ${renderRodadas(partidasSuperior, 'sup')}

                                            <div class="chave-superior-wrapper">
                                                <div class="partidas-wrapper " >
                                                    ${renderFases(partidasSuperior, 'sup')}
                                                </div>
                                            </div>
                                        </div>
                                        ${result.possui_chave_inferior ? `
                                            <div class="chave-inferior">  
                                                <h2 class="title-chave">Chave Inferior</h2>
                                                ${renderRodadas(partidasInferior, 'inf')}
                                                <div class="chave-inferior-wrapper">                                                    
                                                    <div class="partidas-wrapper" >
                                                        ${renderFases(partidasInferior, 'inf')}
                                                    </div>
                                                </div>
                                            </div>
                                        ` : ''}
                                    
                                    </div>`

        content_Resultados.innerHTML = htmlModalidades

    }


		function scrollToTheEnd (){
			const partidaWrapper = document.querySelector('.partidas-wrapper ')
			console.log(partidaWrapper)
			partidaWrapper.scrollLeft = 100000000

		}

		function anchorFases(){
			const links = document.querySelectorAll('.todas-fases .link-fases')
			links.forEach(link => {
				link.addEventListener('click', (e) =>{
					 e.preventDefault();
					 console.log(e.currentTarget.getAttribute('href') )
					 const id = e.currentTarget.getAttribute('href') 
						if (!id) return
					 const scrollToParent = document.querySelector(`${id}`)
					 if(scrollToParent){
						 const scrollTo = scrollToParent.querySelector('.times') 
						 if(scrollTo){
							 scrollTo.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
						 }												
					 }
				})
			})
		}


		function checkIfSticky(element) {
			window.addEventListener('scroll', () => {
					const rect = element.getBoundingClientRect();
					
				
         const mq = window.matchMedia('(max-width: 768px)')
				 const isMobile = mq.matches
					const compare =  (isMobile ? 76 :  100)
					if (rect.top ===  compare) {
							element.classList.add('stuck')
						} else {
							element.classList.remove('stuck')
						
					}
					
			});
	}
	
	
	


    function getTimes() {
        const BASE_URL = `${window.location.protocol}//${window.location.host}`;
        const currentUrl = new URL(window.location.href);
        const pathname = currentUrl.pathname; // Get the pathname without hash or query parameters
        let url = '';

        if (pathname.includes("torneio-equipe")) {
            equipeOrIndividual = 'equipe';
            const segments = pathname.split("/").filter(segment => segment); // Remove empty elements
            const id = segments[segments.length - 1]; // Get the last element which is the ID
            url = `torneio/${id}/`;
        }

        if (pathname.includes("torneio-individual")) {
            equipeOrIndividual = 'individual';
            const segments = pathname.split("/").filter(segment => segment); // Remove empty elements
            const id = segments[segments.length - 1]; // Get the last element which is the ID
            url = `torneio-individual/${id}/`;
        }

        axios.get(`${BASE_URL}/api/competicao/${url}`)
            .then(function (response) {
                console.log(response.data);

                if (response.data) {
                    loadData(response.data, equipeOrIndividual);
                }

								// codigo depois de preencher 

								scrollToTheEnd()
								anchorFases()
								dragAndMove()
								
								// Usage: Apply to any sticky element
								const stickyElement = document.querySelector('.todas-fases');
								
								if(stickyElement){

									checkIfSticky(stickyElement);
								}


            })
            .catch(e => {
                console.log(e);
            });
    }

    getTimes()

}

export default Page_Resultados;

/**
 
                <div class="table-result">
                    <div class="row">
                        <div class="col-xl-4">
                            <span class="block-title"><span>Rodada de 32 superior</span></span>

                            <div class="col-result">
                                <div class="group group-quartas">
                                    <div class="item-table-result">
                                        <div class="infos">
                                            <span class="info"><strong>04/08</strong></span>
                                            <span class="separator"></span>
                                            <span class="info"><strong>18:30</strong></span>
                                        </div>

                                        <div class="teams">
                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME.webp"></div>
                                                <span class="title">VITALITY</span>
                                                <span class="result">2</span>
                                            </div>

                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME_1.webp"></div>
                                                <span class="title">FaZe</span>
                                                <span class="result">4</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-table-result">
                                        <div class="infos">
                                            <span class="info"><strong>04/08</strong></span>
                                            <span class="separator"></span>
                                            <span class="info"><strong>18:30</strong></span>
                                        </div>

                                        <div class="teams">
                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME.webp"></div>
                                                <span class="title">VITALITY</span>
                                                <span class="result">2</span>
                                            </div>

                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME_1.webp"></div>
                                                <span class="title">FaZe</span>
                                                <span class="result">4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="group group-quartas">
                                    <div class="item-table-result">
                                        <div class="infos">
                                            <span class="info"><strong>04/08</strong></span>
                                            <span class="separator"></span>
                                            <span class="info"><strong>18:30</strong></span>
                                        </div>

                                        <div class="teams">
                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME.webp"></div>
                                                <span class="title">VITALITY</span>
                                                <span class="result">2</span>
                                            </div>

                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME_1.webp"></div>
                                                <span class="title">FaZe</span>
                                                <span class="result">4</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-table-result">
                                        <div class="infos">
                                            <span class="info"><strong>04/08</strong></span>
                                            <span class="separator"></span>
                                            <span class="info"><strong>18:30</strong></span>
                                        </div>

                                        <div class="teams">
                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME.webp"></div>
                                                <span class="title">VITALITY</span>
                                                <span class="result">2</span>
                                            </div>

                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME_1.webp"></div>
                                                <span class="title">FaZe</span>
                                                <span class="result">4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="group group-quartas">
                                    <div class="item-table-result">
                                        <div class="infos">
                                            <span class="info"><strong>04/08</strong></span>
                                            <span class="separator"></span>
                                            <span class="info"><strong>18:30</strong></span>
                                        </div>

                                        <div class="teams">
                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME.webp"></div>
                                                <span class="title">VITALITY</span>
                                                <span class="result">2</span>
                                            </div>

                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME_1.webp"></div>
                                                <span class="title">FaZe</span>
                                                <span class="result">4</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-table-result">
                                        <div class="infos">
                                            <span class="info"><strong>04/08</strong></span>
                                            <span class="separator"></span>
                                            <span class="info"><strong>18:30</strong></span>
                                        </div>

                                        <div class="teams">
                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME.webp"></div>
                                                <span class="title">VITALITY</span>
                                                <span class="result">2</span>
                                            </div>

                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME_1.webp"></div>
                                                <span class="title">FaZe</span>
                                                <span class="result">4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="group group-quartas">
                                    <div class="item-table-result">
                                        <div class="infos">
                                            <span class="info"><strong>04/08</strong></span>
                                            <span class="separator"></span>
                                            <span class="info"><strong>18:30</strong></span>
                                        </div>

                                        <div class="teams">
                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME.webp"></div>
                                                <span class="title">VITALITY</span>
                                                <span class="result">2</span>
                                            </div>

                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME_1.webp"></div>
                                                <span class="title">FaZe</span>
                                                <span class="result">4</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-table-result">
                                        <div class="infos">
                                            <span class="info"><strong>04/08</strong></span>
                                            <span class="separator"></span>
                                            <span class="info"><strong>18:30</strong></span>
                                        </div>

                                        <div class="teams">
                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME.webp"></div>
                                                <span class="title">VITALITY</span>
                                                <span class="result">2</span>
                                            </div>

                                            <div class="team">
                                                <div class="logo"><img loading="lazy" src="https://django-meg.s3.amazonaws.com/media/equipes/logos/LOGO_TIME_1.webp"></div>
                                                <span class="title">FaZe</span>
                                                <span class="result">4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

 */