import axios from "axios";
const BASE_URL = "https://restcountries.com/v3.1";


export const load_paises = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/all`);
        return response.data;
    }
    catch (error) {
        console.log(error);
    }
}


export const traduzir_pais = async (nome_pais) => {
    try {
        const resposta = await axios.get(`https://api.mymemory.translated.net/get?q=${nome_pais}&langpair=pt|en`);
        return resposta.data;
    }
    catch (error) {
        console.log(error)
    }
}

export const search_ddi_pais = async (nome_pais_ingles) => {
    try {
        const resposta = await axios.get(`https://restcountries.com/v3.1/name/${nome_pais_ingles}`);
        const DDI = `${resposta.data[0].idd.root}${resposta.data[0].idd?.suffixes[0]}`;
        return DDI;
    }
    catch (error) {
        console.log(error)
    }
}


/*
export const load_estados = async (id_paise) => {
    try {
        const response = await axios.get(`${BASE_URL}/paises/${id_paise}`);
        return response.data;
    }
    catch (error) {
        console.log(error);
    }
}
*/