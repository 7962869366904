import { find, findAll } from "../utils/dom";
import axios from "axios";

const Single_Noticias = () => {
    const page = find("[js-page='single_noticia']");
    if (!page) return;

    const Site_Base = `${document.location.href}`;
    const BASE_URL = `${window.location.protocol}//${window.location.host}`;

    function Compartilhe_Facebook() {
        const BT_Facebook = findAll('[js-bt-facebook]');
        if (!BT_Facebook) return;

        BT_Facebook?.forEach((bt) => {
            bt.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(Site_Base)}`;

        });
    }

    function Compartilhe_X() {
        const BT_X = findAll('[js-bt-x]');
        if (!BT_X) return;

        BT_X?.forEach((bt) => {
            bt.href = `https://twitter.com/intent/tweet?url=${encodeURIComponent(Site_Base)}`;
        })
    }

    function Compartilhe_Twitch() {
        const BT_Twitch = findAll('[js-bt-twitch]');
        if (!BT_Twitch) return;

        BT_Twitch?.forEach((bt) => {
            bt.href = `https://www.twitch.tv/share?url=${encodeURIComponent(Site_Base)}`;
        })
    }

    function sortear_posts(max) {
        let min = 0;
        const NumArray = new Array();

        const minCeiled = Math.ceil(min);
        const maxFloored = Math.floor(max);

        if (max > 2) {
            for (let i = 0; i < 3; i++) {

                let break_i = true;

                while (break_i) {
                    let numResult = Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
                    if (!NumArray.includes(numResult)) {
                        NumArray.push(numResult);
                        break_i = false;
                    }
                }
            }
        }


        return NumArray;

    }

    function load_posts() {
        let Categoria = find("[js-categoria-noticias]");
        if (!Categoria) return;

        Categoria = Categoria.getAttribute('js-categoria-noticias');


        axios.get(`${BASE_URL}/api/posts/?${(Categoria != '') ? `categoria=${Categoria}` : ''}`).then(function (response) {

            const Sugestoes_Content = find("[js-sugestoes-content]");
            if (!Sugestoes_Content) return;

            const Resultados = response.data.results;
            let posts = '';

            const ArraySugestoes = sortear_posts(Resultados.length);
            if (ArraySugestoes.length == 0) {
                find(".box-title-sugestoes").style.display = "none";
                Sugestoes_Content.style.display = "none";
            }


            for (let ItemSugestoes of ArraySugestoes) {
                posts += `
                    <div class="col-xl-4">
                            <div class="card-article card-article-medium card-article-2">
                                <a href="${BASE_URL}/noticias/${Resultados[ItemSugestoes].slug}" class="image">
                                    <img loading="lazy" src="${(Resultados[ItemSugestoes].banner) ? Resultados[ItemSugestoes].banner : `${BASE_URL}/static/img/default/default.webp`}">
                                    <span class="category">${Resultados[ItemSugestoes].categoria}</span>
                                </a>
                                <div class="box-text">
                                    <a href="${BASE_URL}/noticias/${Resultados[ItemSugestoes].slug}" class="title">${Resultados[ItemSugestoes].titulo}</a>
                                </div>
                            </div>
                        </div>
                `
            }

            Sugestoes_Content.innerHTML = posts;
            /*for (let i = 0; i < Resultados.length; i++) {
                posts += `
                    <div class="col-xl-4">
                            <div class="card-article card-article-medium card-article-2">
                                <a href="${BASE_URL}/noticias/${Resultados[i].slug}" class="image">
                                    <img loading="lazy" src="${(Resultados[i].banner) ? Resultados[i].banner : `${BASE_URL}/static/img/default/default.webp`}">
                                    <span class="category">${Resultados[i].categoria}</span>
                                </a>
                                <div class="box-text">
                                    <a href="${BASE_URL}/noticias/${Resultados[i].slug}" class="title">${Resultados[i].titulo}</a>
                                </div>
                            </div>
                        </div>
                `
            }

            Sugestoes_Content.innerHTML = posts;
            Global_Todos_Post = Resultados.length;*/


        }).catch(function (error) {
            console.log(error);
        });
    }




    Compartilhe_Facebook();
    Compartilhe_X();
    Compartilhe_Twitch();
    load_posts();
}

export default Single_Noticias;