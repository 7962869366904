import { copyToClipboard, find, findAll } from "../utils/dom";
import { traduzir_pais, search_ddi_pais } from "../api/Paises";
import { load_passos, get_passo, active_passo, update_passo } from "../modules/cadastro_passos";
import axios from "axios";
import qs from 'qs';
import showToastify from "../utils/toastify";



const PageCadastro = () => {
	const page = find("[js-page='cadastro']");
	if (!page) return;

	const BASE_URL = `${window.location.protocol}//${window.location.host}`;
	let ATUAL_FORM = 1;
	let PLATAFORMA_INSCRICAO = null;
	let MODALIDADE = '';
	let IDADE_MINIMA = 0;

	const FormCadastro = find('#form-cadastro');

	/*FormCadastro?.addEventListener("submit", (e) => {
		//e.preventDefault();
		const Dados_Form = new FormData(e.target);
		Dados_Form.forEach((item, key) => {
			if (key === 'email') {
				localStorage.setItem('email', item);
			}
		})
	})*/

	//console.log(new FormData(FormCadastro))
	function valida_form() {
		const dados_form = new FormData(FormCadastro);

		const nome = dados_form.get('nome');
		const genero = dados_form.get('genero');
		const data_nascimento = dados_form.get('data_nascimento');
		const telefone = dados_form.get('telefone');
		const pais = dados_form.get('pais');
		const estado = dados_form.get('estado');
		const cidade = dados_form.get('cidade');
		const email = dados_form.get('email');
		const senha = dados_form.get('senha');
		const errors = new Array();
		const campo_de_erro = find('[js-errors-form]');

		if (!nome) {
			errors.push('<p>O campo nome é obrigatório</p>');
		}

		if (!genero) {
			errors.push("<p>O campo gênero é obrigatório</p>");
		}

		if (!data_nascimento) {
			errors.push("<p>O campo data de nascimento é obrigatório</p>");
		}
		else {
			const data = data_nascimento.split("/");
			const dia = parseInt(data[0]);
			const mes = parseInt(data[1]) - 1;
			const ano = parseInt(data[2]);

			const resultado_data = new Date(ano, mes, dia);

			if (!(dia == resultado_data.getDate() && mes == resultado_data.getMonth() && ano == resultado_data.getFullYear())) {
				errors.push("<p>Data de nascimento inválida</p>");
			}
		}

		if (!telefone) {
			errors.push("<p>O campo telefone é obrigatório</p>");
		}

		if (!pais) {
			errors.push("<p>O campo pais é obrigatório</p>");
		}

		if (!estado) {
			errors.push("<p>O campo estado é obrigatório</p>");
		}

		if (!cidade) {
			errors.push("<p>O campo cidade é obrigatório</p>");
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			errors.push("<p>Por favor, insira um email válido</p>");
		}

		//const senhaRegex = /[A-Z]/;


		if (!senha) {

			errors.push("<p>O campo senha é obrigatório</p>");
		} else {
			if (senha.length < 6) {
				errors.push("<p>Senha deve conter no mínimo 6 caracteres</p>");
			}

			if (!/[a-z]/.test(senha)) {
				errors.push("<p>A senha deve conter pelo menos 1 caracter em minúsculo.</p>");
			}

			if (!/[A-Z]/.test(senha)) {
				errors.push("<p>A senha deve conter pelo menos 1 caracter em maiúsculo.</p>");
			}

			if (!/[0-9]/.test(senha)) {
				errors.push("<p>A senha deve conter pelo menos 1 número</p>");
			}

			if (!/[@$!%*?&#]/.test(senha)) {
				errors.push("<p>A senha deve conter pelo menos 1 caracter especial</p>");
			}
		}

		if (errors.length > 0) {
			campo_de_erro.innerHTML = '';
			errors.forEach((erro) => {
				campo_de_erro.insertAdjacentHTML('afterbegin', erro);
			})
			return false;
		}

		return true;
	}

	function add_nome_plataforma_inscricao(nome_modalidade) {
		const Field_Nome_Modalidade = findAll('[js-name-modalidade]');
		Field_Nome_Modalidade?.forEach((item) => {
			item.innerText = nome_modalidade;
		})
	}

	async function set_option_paises() {
		const Select_Paises = find('[js-paises]');
		if (!Select_Paises) return;

		/*const paises = await load_paises();
		paises.sort(function (a, b) {
			if (a.name.common > b.name.common) {
				return 1;
			}
			if (a.name.common < b.name.common) {
				return -1;
			}
			// a must be equal to b
			return 0;
		});

		//console.log(paises)
		for (let single_paise of paises) {

			const option = document.createElement('option');
			option.value = single_paise.name.common;
			option.text = single_paise.name.common;

			const idd = `${single_paise.idd.root}${(single_paise.idd.suffixes) ? `${single_paise.idd.suffixes[0]}` : ''}`
			option.setAttribute('pais-ddd', idd);

			Select_Paises.appendChild(option);

		}*/

		Select_Paises.addEventListener("change", async function (event) {
			// Verifica qual option foi selecionado
			const selectedOption = event.target.options[event.target.selectedIndex];
			let pais_em_ingles = await traduzir_pais(selectedOption.innerText);
			const pais_traduzido = pais_em_ingles.responseData.translatedText?.split(':')[0]


			const DDI = await search_ddi_pais(pais_traduzido);
			const FieldDDD = find('[js-ddd]');
			FieldDDD.value = DDI;


			/*const DDD = selectedOption.getAttribute('pais-ddd');
			const FieldDDD = find('[js-ddd]')
			FieldDDD.value = DDD;*/


			//const id_pais = selectedOption.getAttribute('js-paise-id');
			//set_option_estados(id_pais);

		});
	}

	function status_forms(id_form) {
		find('[js-form-cadastro].active').classList.remove('active');

		const NovoFormAtivo = find(`${id_form}`);
		NovoFormAtivo.classList.add('active');

	}

	function update_form() {

		if (!valida_form()) return;

		ATUAL_FORM += 1;

		if (ATUAL_FORM > 2) {
			const Field_Nome_Modalidade = findAll('[js-name-modalidade]');
			Field_Nome_Modalidade?.forEach((nome_modalidade) => {
				nome_modalidade.innerText = MODALIDADE;
			})
			if (PLATAFORMA_INSCRICAO == 'Gamers Club') {
				ATUAL_FORM = 3;
				//FormCadastro.submit()

			} else if (PLATAFORMA_INSCRICAO == 'Challenger Mode') {
				ATUAL_FORM = 6;
			} else {
				ATUAL_FORM = 2;
			}
		}


		status_forms(`#cadastro_${ATUAL_FORM}`);
		window.location.href = `${BASE_URL}${window.location.pathname}#content-cadastro`;
		update_passo((ATUAL_FORM > 3) ? 3 : ATUAL_FORM);
	}

	const load_click_passos = () => {
		const passos = findAll('[js-footstep]');
		passos?.forEach((passo) => {
			passo?.addEventListener("click", (e) => {
				const atual_passo = get_passo(e.target);
				if (atual_passo && atual_passo <= ATUAL_FORM) {
					update_passo(atual_passo);
					ATUAL_FORM = atual_passo;
					if (ATUAL_FORM == 1) {
						PLATAFORMA_INSCRICAO = null
					}
					status_forms(`#cadastro_${ATUAL_FORM}`);
				}
			})
		})

	}



	function botao_avancar() {
		const Botaos = findAll('[js-bt-avanca]');

		Botaos.forEach((Botao) => {
			Botao?.addEventListener("click", () => {

				update_form();
			})
		})

	}

	function get_atual_form() {
		const search_atual_form = window.location.search?.split('?')[1]?.split('&');
		search_atual_form?.forEach((search) => {
			if (search.includes('atual_form')) {
				ATUAL_FORM = search.split('=')[1]
				status_forms(`#cadastro_${ATUAL_FORM}`);
				window.location.href = `${BASE_URL}${window.location.pathname}${window.location.search}#content-cadastro`;
				update_passo((ATUAL_FORM > 3) ? 3 : ATUAL_FORM);

			}
		})
	}


	/** Resetando o campos visiveis da modalidade */
	function reset_field_modalidade() {
		const resets = findAll(`#plataforma_inscricao option.true_idade`);
		resets.forEach((reset) => {
			reset.classList.remove('true_idade');
		})
	}


	/** Esta função atualiza a disponibilidade das option conforme a idade */
	function atualiza_field_modalidades() {
		const ULGambiarra = find('[js-gambiarra-modalidades]');
		const LIGambiarra = ULGambiarra.querySelectorAll('li');

		reset_field_modalidade();

		const Option_Validas = [...LIGambiarra].filter(value => {
			const idade_minima = parseInt(value.getAttribute('js-idade-minima'));
			return (IDADE_MINIMA >= idade_minima);
		})


		Option_Validas.forEach((option) => {
			const id_modalidade = option.getAttribute('id-modalidade');
			find(`#plataforma_inscricao option[value='${id_modalidade}']`).classList.add('true_idade');
		})


	}


	set_option_paises();
	botao_avancar();
	get_atual_form();


	find('#plataforma_inscricao')?.addEventListener("change", (e) => {
		let value = e.target.value;
		const ULGambiarra = find('[js-gambiarra-modalidades]');

		const SelecGambiarra = ULGambiarra.querySelector(`[id-modalidade='${value}']`);

		const Nome_Modalidade = SelecGambiarra.innerText;
		const Plataforma = SelecGambiarra.getAttribute('js-inscricao-plataform');

		MODALIDADE = Nome_Modalidade;
		localStorage.setItem("modalidade", Nome_Modalidade);
		PLATAFORMA_INSCRICAO = Plataforma;
	})


	/** Modificando a idade minima de maneira global */
	find('#id_data_nascimento')?.addEventListener("change", (e) => {
		const value = e.target.value;
		const array_data = value.split('/'); //transforma em um array [dia, mes, ano]
		const ano_atual = new Date().getFullYear(); // Pega o ano atual
		IDADE_MINIMA = ano_atual - parseInt(array_data[2]);

		atualiza_field_modalidades()
	}, false)









	FormCadastro?.addEventListener("submit", (e) => {
		//e.preventDefault();
		const Dados_Form = new FormData(e.target);
		const obj = {}

		const fields = [...FormCadastro.querySelectorAll('[name]')].reduce((acc, i) => {

			return acc
		}, {})



		// console.log(e.target.instance.codigo)
		console.log("Dados form", Dados_Form);

	})

	const BTGerarCodigo = find('[js-bt-gerar-codigo]');

	BTGerarCodigo?.addEventListener("click", (e) => {
		axios.get(`${BASE_URL}/inscricao?codigo=rodape`)
			.then((result) => {
				console.log(result);
			})
			.catch((error) => {
				console.log(error)
			})
	})


	function showLoader() {
		document.body.classList.add('show-loader')

	}


	const BTNAvancarLoader = find('[js-btn-loader]', FormCadastro)
	BTNAvancarLoader?.addEventListener('click', showLoader)

	const BTFinalizarInscricao = find('[js-finalizar-inscricao]');
	BTFinalizarInscricao?.addEventListener("click", () => {
		ATUAL_FORM = 5;
		status_forms(`#cadastro_${ATUAL_FORM}`);
		window.location.href = `${BASE_URL}${window.location.pathname}${window.location.search}#content-cadastro`;
		update_passo((ATUAL_FORM > 3) ? 3 : ATUAL_FORM);
	})



	const todos_passos = load_passos();
	active_passo(todos_passos[0]);
	load_click_passos();

	// Registro();

	function showHideSection(step, params) {
		const sections = findAll('[js-form-cadastro]')

		sections.forEach((section) => section.classList.remove('active'))

		if (step === 'complete') {
			find('#cadastro_6').classList.add('active')
			find('.footsteps').classList.add('hidden')
			//update_passo(3);
			add_nome_plataforma_inscricao(localStorage.getItem("modalidade"));
		}



		if (step === 'code') {
			find('#cadastro_3').classList.add('active')
			find('.footsteps').classList.add('hidden')
			find('#codigo-gamer').value = params.codigo
			find('[js-copy]').addEventListener('click', () => copyToClipboard(params.codigo))
			add_nome_plataforma_inscricao(localStorage.getItem("modalidade"));
		}



		if (step === 'verify-email') {
			find('#cadastro_4').classList.add('active')
			find('.footsteps').classList.add('hidden')
			find('.email-codigo').innerText = params.email



		}

	}

	function onInit() {
		const { search } = window.location
		const params = qs.parse(search, { ignoreQueryPrefix: true })

		if (!params.step) return

		const { step } = params
		document.body.setAttribute('data-form', step)
		showHideSection(step, params)



	}

	onInit()


	const titlePageInscricao = () => {
		const url = document.title
		document.title = `${url} Inscrição`
	}

	titlePageInscricao()
}

export default PageCadastro;