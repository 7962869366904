import { find, findAll } from "../utils/dom";
import axios from "axios";


const PageTimes = () => {
    const page = find("[js-page='times']");
    if (!page) return;

    const BASE_URL = `${window.location.protocol}//${window.location.host}`;

    let Global_Limit = 1;

    let GlobalTimes = new Array();
    let GlobalModalidades = new Array();
    let GlobalPaises = new Array();


    function reset_content_resultados() {
        const Content_Resultados = find("[js-content-resultados]");
        if (!Content_Resultados) return;
        Content_Resultados.innerHTML = '';
    }

    function get_modalidades(modalidade) {
        let result_modalidades = '';
        if (!modalidade) return "";

        for (let [index, item] of modalidade.entries()) {
            if (index == 0) {
                result_modalidades = item.nome
            }
            else {
                result_modalidades += `, ${item.nome}`
            }
        }


        return result_modalidades;

    }

    function load_posts(limit = 12, query_busca = '') {
        //let Categoria = busca_categoria();
        const loading = find('[js-loading]');
        loading.style.display = 'block';


        axios.get(`${BASE_URL}/api/equipes/?limit=${limit}${query_busca}`).then(function (response) {
            const Container_Posts = find('[js-posts]');
            if (!Container_Posts) return;

            Container_Posts.style.display = 'none';

            const Resultados = response.data.results;
            let posts = '';

            console.log(Resultados)

            for (let i = 0; i < Resultados.length; i++) {
                posts += `

                <div class="col-xl-3 col-desk-small">
                    <a href="${BASE_URL}/equipes/${Resultados[i].slug}" class="card-team">
                        <img loading="lazy" class="w-[384px] h-[218px] object-cover object-top" src="${Resultados[i].capa}">
                        <div class="into">
                            <div class="logo">
                                <img loading="lazy" src="${Resultados[i].logo}">
                            </div>
                            <div class="text">
                                <div class="top">
                                    <span class="subtitle">${get_modalidades(Resultados[i].modalidades)}</span>
                                    <span class="country">${Resultados[i].pais}</span>
                                </div>
                                <span class="title">${Resultados[i].nome}</span>
                            </div>
                        </div>
                    </a>
                </div>
                `
            }

            Container_Posts.innerHTML = posts;

            setTimeout(() => {
                loading.style.display = 'none';
                Container_Posts.style.display = 'flex';
            }, 300)

            // console.log(loading)


        }).catch(function (error) {
            console.log(error);
        });
    }

    function get_busca() {

        if (GlobalTimes.length == 0 && GlobalModalidades.length == 0 && GlobalPaises == 0) {
            load_posts(Global_Limit);
            return;
        }

        let query_busca = '';

        if (GlobalTimes.length != 0) {
            query_busca += `&slug__in=`
            for (let [index, item] of GlobalTimes.entries()) {
                if (index == 0) {
                    if (item === 'todos')
                    query_busca += ''
                    else
                    query_busca += `${item}`
                }
                else {
                    query_busca += `,${item}`
                }

            }
        }

        if (GlobalModalidades.length != 0) {
            query_busca += `&modalidades=`
            for (let [index, item] of GlobalModalidades.entries()) {
                if (index == 0) {
                    query_busca += `${item}`
                }
                else {
                    query_busca += `,${item}`
                }

            }
        }

        if (GlobalPaises.length != 0) {
            query_busca += `&paises=`
            for (let [index, item] of GlobalPaises.entries()) {
                if (index == 0) {
                    query_busca += `${item}`
                }
                else {
                    query_busca += `,${item}`
                }
            }
        }

        console.log(GlobalModalidades, GlobalTimes, GlobalPaises)

        load_posts(Global_Limit, query_busca);
    }

    function load_paises() {
        const Paises = findAll('[js-pais]');
        if (!Paises) return;

        for (let item of Paises) {
            item?.addEventListener("click", (e) => {
                const value_pais = e.target.getAttribute('js-pais');
                const input = e.target.querySelector('input');
                const todos = e.target.closest('.box-hide').querySelector('[js-pais="todos"] input')
                if (!input) return;

                reset_content_resultados();

                if(value_pais=== 'todos'){
                    GlobalPaises = [];
                
                    const allInputsPaises = document.querySelectorAll('[js-pais] input')
                  
                    allInputsPaises.forEach(i => {                    
                        i.checked = false
                    });
                }else{
                    if (!input.checked) {

                        GlobalPaises.push(value_pais);
                        todos.checked = false
    
                    } else {
    
                        const indexRemove = GlobalPaises.indexOf(value_pais);
                        if (indexRemove > -1) {
                            GlobalPaises.splice(indexRemove, 1);
                        }
                    }
                }

               

                get_busca();
            })
        }
    }

    function load_modalidades() {
        const Modalidade = findAll('[js-modalidade]');
        if (!Modalidade) return;

        for (let item of Modalidade) {
            item?.addEventListener("click", (e) => {
                const value_time = e.target.getAttribute('js-modalidade');
                const input = e.target.querySelector('input');
                const todos = e.target.closest('.box-hide').querySelector('[js-modalidade="todos"] input')
                if (!input) return;

                reset_content_resultados();

                if(value_time === 'todos'){
                    GlobalModalidades = [];
                
                    const allInputsPaises = document.querySelectorAll('[js-modalidade] input')
                  
                    allInputsPaises.forEach(i => {                    
                        i.checked = false
                    });
                }else{
                    if (!input.checked) {

                        GlobalModalidades.push(value_time);
                        todos.checked = false
                    } else {
    
                        const indexRemove = GlobalModalidades.indexOf(value_time);
                        if (indexRemove > -1) {
                            GlobalModalidades.splice(indexRemove, 1);
                        }
                    }
                }
               

                get_busca();


            })
        }
    }

    function load_times() {
        const Time = findAll('[js-equipe]');
        if (!Time) return;

        for (let item of Time) {
            item?.addEventListener("click", (e) => {
                const value_time = e.target.getAttribute('js-equipe');
                const input = e.target.querySelector('input');
              
                if (!input) return;
                const todos = e.target.closest('.box-hide').querySelector('[js-equipe="todos"] input')

                reset_content_resultados();

                if(value_time=== 'todos'){
                    GlobalTimes = [];
                
                    const allInputsPaises = document.querySelectorAll('[js-equipe] input')
                  
                    allInputsPaises.forEach(i => {                    
                        i.checked = false
                    });
                }else {
                    if (!input.checked) {

                        GlobalTimes.push(value_time);
                        todos.checked = false
                    }
                    else {
    
                        const indexRemove = GlobalTimes.indexOf(value_time);
                        if (indexRemove > -1) {
                            GlobalTimes.splice(indexRemove, 1);
                        }
                    }
                }
                

                get_busca();
            })
        }
    }

        /*
        const allTimes = document.querySelectorAll('[js-equipe]')
        allTimes.forEach(time =>{
            time.addEventListener("click", (e)=>{
              console.log("js-equipe", e.target);    
              const all = document.querySelector('[js-equipe="todos"] input')
              all.removeAttribute('checked')
              console.log("todos", all);
              
            })
        })
        */

    // const ClickAllInputChecked = () =>{
    //     const clickBtnAll = document.querySelector('[js-equipe="todos"] input')
    //     clickBtnAll.addEventListener('click', (e)=>{
    //         const alltimes = document.querySelectorAll('[js-equipe] input')
    //         alltimes.forEach(time =>{
    //             time.removeAttribute('checked')
    //             time.checked = false
    //             console.log("Click", time.checked);
    //         })
    //         clickBtnAll.setAttribute('checked', 'checked');
    //         clickBtnAll.checked = true
    //         console.log("Click", e.target);
    //     })
    // }

    // const clickInputOne = () =>{
    //     const allTimes = document.querySelectorAll('[js-equipe]')
    //     allTimes.forEach(time =>{
    //         time.addEventListener("click", (e)=>{
    //           console.log("js-equipe", e.target);    
    //           const all = document.querySelector('[js-equipe="todos"] input')
    //           all.removeAttribute('checked')
    //           console.log("todos", all);
              
    //         })
    //     })
    // }
        
    // ClickAllInputChecked()
    // clickInputOne()


    load_times()
    load_modalidades()
    load_paises()
    load_posts()
}

export default PageTimes;