import { find, findAll } from "../utils/dom";
import axios from "axios";

const Page_Resultados = () => {
    const Page = find("[js-page='resultados']");
    if (!Page) return;

    const BASE_URL = `${window.location.protocol}//${window.location.host}`;
    let Global_Limit_CardBox = 2;
    const GlobalModalidades = new Array();
    const GlobalAno = new Array();


    function reset_content_resultados() {
        const Content_Resultados = find("[js-content-resultados]");
        Content_Resultados.innerHTML = '';
    }

    function load_resultados(limit_cardbox = 2, query = false, flag_first = false) {

        axios.get(`${BASE_URL}/api/competicao/resultados/?ordering=-data_resultado${(query) ? `${query}` : ''}`).then(function (response) {




            const Content_Resultados = find("[js-content-resultados]");
            if (!Content_Resultados) return;

            const Loading = find('[js-content-carregando]');
            Loading.style.display = "block";

            const Dados_Resultados = response.data.results;
            let dia_fixo = 0;
            let mes_fixo = 0;
            let atual_limit_cardbox = 1;

            let ContentHTML = '';

            const BT_Mais_Resultados = find("[js-bt-mais-resultados]");


            const Resultados_Filtrados = Dados_Resultados.filter((value) => {
                const hoje_ano = new Date();
                if (value.data_resultado.includes(`${hoje_ano.getFullYear()}`)) {
                    return value;
                }
            });


            //Content_Resultados.innerHTML = '';

            for (let [index, resultado_unitario] of Resultados_Filtrados.entries()) {

                const atual_data = new Date(resultado_unitario.data_resultado);
                const atual_dia = atual_data.getDate();
                const atual_mes = atual_data.getMonth() + 1;
                const Hora = atual_data.getHours();
                const Minutos = atual_data.getMinutes();

                if (atual_limit_cardbox > limit_cardbox) {
                    BT_Mais_Resultados.style.display = "flex";
                    break;
                }
                else {

                    BT_Mais_Resultados.style.display = "none";
                }

                if (dia_fixo != atual_dia && mes_fixo != atual_mes) {
                    ContentHTML += `
                        <div class="block">
                            <div class="box-title-detail">
                                <h2>
                                    <span>
                                        ${(atual_dia < 10) ? `0${atual_dia}` : atual_dia}/${(atual_mes < 10) ? `0${atual_mes}` : atual_mes}
                                    </span>
                                </h2>
                            </div>
                    `
                }

                ContentHTML += `
                    <div class="item-result">
                        <span class="title">${resultado_unitario.modalidade}</span>

                        <div class="box-result">
                            <span class="info-left">
                                ${(Hora < 10) ? `0${Hora}` : Hora}:${(Minutos < 10) ? `0${Minutos}` : Minutos}
                            </span>

                            <div class="center">
                                <div class="team team-1">
                                    <span class="title-team">
                                        ${resultado_unitario.equipe_1.nome}
                                    </span>
                                    <div class="logo"><img loading="lazy" src="${resultado_unitario.equipe_1.logo}"></div>
                                </div>

                                <span class="result"><span>${resultado_unitario.placar_equipe_1}</span>  x  <span>${resultado_unitario.placar_equipe_2}</span></span>

                                <div class="team team-2">
                                    <span class="title-team">${resultado_unitario.equipe_2.nome}</span>
                                    <div class="logo"><img loading="lazy" src="${resultado_unitario.equipe_2.logo}"></div>
                                </div>
                            </div>

                            <span class="info-right">${resultado_unitario.sistema_resultado}</span>
                        </div>
                    </div>
                `


                if (Dados_Resultados[index + 1]) {
                    const proxima_data = new Date(Dados_Resultados[index + 1].data_resultado);
                    const proximo_dia = proxima_data.getDate();
                    const proximo_mes = proxima_data.getHours() + 1;

                    if (atual_dia != proximo_dia && atual_mes != proximo_mes) {
                        ContentHTML += `
                            </div>
                        `
                        atual_limit_cardbox++;
                    }
                }
                else {
                    ContentHTML += `
                            </div>
                        `
                }


                dia_fixo = atual_dia;
                mes_fixo = atual_mes;


            }

            if (Resultados_Filtrados.length == 0) {
                ContentHTML = `
                    <div class="block">
                        <div class="box-title-detail">
                            <h2><span>Não foi encontrado nenhum resultado.</span></h2>
                        </div>
                    </div>
                `
            }

            if (flag_first == true && Resultados_Filtrados.length > 0) {
                reset_content_resultados();
            }

            Content_Resultados.insertAdjacentHTML("afterbegin", ContentHTML);


        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            const Loading = find('[js-content-carregando]');
            console.log(Loading)
            if (!Loading) return;

            Loading.style.display = "none";
        });

    }


    function load_bt_mais_resultados() {
        const BT_Mais_Resultados = findAll("[js-bt-mais-resultados]");
        BT_Mais_Resultados?.forEach((bt) => {
            bt.addEventListener("click", (e) => {
                e.preventDefault();

                reset_content_resultados();

                Global_Limit_CardBox++;
                load_resultados(Global_Limit_CardBox);

            })
        })
    }

    function get_busca() {
        if (GlobalModalidades.length == 0 && GlobalAno.length == 0) {
            load_resultados(Global_Limit_CardBox);
            return;
        }

        let query_busca = '';
        for (let item of GlobalModalidades) {
            query_busca += `&modalidade=${item}`
        }

        /* for (let item of GlobalAno) {
             query_busca += `&data_resultado__date=${item}`
         }*/


        if (GlobalAno.length != 0) {
            query_busca += `&datas=`
            for (let [index, item] of GlobalAno.entries()) {
                if (index == 0) {
                    query_busca += `${item}`
                }
                else {
                    query_busca += `,${item}`
                }

                //console.log(query_busca)

            }
        }



        load_resultados(Global_Limit_CardBox, query_busca);
    }

    function load_modalidades() {
        const modalidade = findAll('[js-modalidade]');
        if (!modalidade) return;

        for (let item of modalidade) {
            item?.addEventListener("click", (e) => {
                const value_modalidade = e.target.getAttribute('js-modalidade');
                const input = e.target.querySelector('input');
                if (!input) return;


                reset_content_resultados();

                if (!input.checked) {

                    GlobalModalidades.push(value_modalidade);

                }
                else {

                    const indexRemove = GlobalModalidades.indexOf(value_modalidade);
                    if (indexRemove > -1) {
                        GlobalModalidades.splice(indexRemove, 1);
                    }
                }

                //console.log(GlobalModalidades)

                get_busca();
            })
        }
    }

    function load_ano() {
        const data = findAll('[js-data]');
        if (!data) return;

        for (let item of data) {
            item?.addEventListener("click", (e) => {
                const value_data = e.target.getAttribute('js-data');
                const input = e.target.querySelector('input');
                if (!input) return;


                reset_content_resultados();

                if (!input.checked) {

                    GlobalAno.push(value_data);

                }
                else {

                    const indexRemove = GlobalAno.indexOf(value_data);
                    if (indexRemove > -1) {
                        GlobalAno.splice(indexRemove, 1);
                    }
                }

                //console.log(GlobalAno)

                get_busca();
            })
        }
    }

    load_resultados(Global_Limit_CardBox, false, true);
    load_bt_mais_resultados();
    load_modalidades();
    load_ano();
}

export default Page_Resultados;